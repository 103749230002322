import { convertDataToDataBindingContract } from '../data/utils'

export default class StaticCache {
  constructor(routerPayload) {
    if (routerPayload && routerPayload.config) {
      //TODO: remove additional checks after TB stop passing memberData via routerData
      // https://wix.slack.com/archives/C0107T7BLSC/p1641982577002100
      this.#collectionId = routerPayload.config.dataset.collectionName
      this.#items = routerPayload.items
      this.#schemas = routerPayload.schemas
      const cursor = routerPayload.nextCursor || undefined
      const total = routerPayload.totalCount || 0
      const loaded = (routerPayload.items || []).length
      this.#datasetSize = {
        cursor,
        total,
        loaded,
      }
    }
  }

  getSchemas() {
    return this.#schemas
  }

  getDataStore() {
    if (this.#items) {
      return convertDataToDataBindingContract({
        items: this.#items,
        datasetSize: this.#datasetSize,
        collectionId: this.#collectionId,
      })
    }
  }

  getItems() {
    return this.#items
  }

  #collectionId
  #items
  #schemas
  #datasetSize
}
