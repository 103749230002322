import { flow } from 'lodash'
import formatValue from './formatValue'
import convertValue from './transformFromRecordToView'
import transformFromViewToRecord from './transformFromViewToRecord'

const transformFromRecordToView = ({
  value,
  role,
  fieldType,
  propPath,
  componentIsInput,
  format,
  utils: { formatter } = {},
}) =>
  flow([
    value => formatValue({ value, formatter, fieldType, format }),
    value =>
      convertValue(value, {
        fieldType,
        role,
        componentIsInput,
        propPath,
      }),
  ])(value)

export { transformFromRecordToView, transformFromViewToRecord }
