import { orderBy, uniqBy } from 'lodash'
import type { ComponentDatabindingApiFactory } from './types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import type { RecordStoreRecord } from '../record-store/service'
import { appContext } from '../viewer-app-module/DataBindingAppContext'
import { VerboseMessage } from '../logger'
import { transformFromRecordToView } from '../components/transformData'
import { getFieldValue } from '../components/helpers'
import {
  getFieldReferencedCollection,
  getSchemaDisplayField,
} from '../data/utils'
import type { AdaptedComponentWithOptions } from '../inverted-dependencies/components'
import type { DropdownOptionsConnectionConfig } from '../types'
import { isNonEmptyConfig } from '../components/helpers/connectionConfigUtils'

type DataTransformer = (value: any) => any

interface DropDownOption {
  value: string
  label: string
}

interface OptionMapperConfig {
  valueField: string
  labelField?: string
  dataTransformer: DataTransformer
}

const createOption = (
  record: RecordStoreRecord,
  { valueField, labelField, dataTransformer }: OptionMapperConfig,
): DropDownOption => {
  const value = dataTransformer(getFieldValue(record, valueField))
  return {
    value,
    label: labelField
      ? dataTransformer(getFieldValue(record, labelField))
      : value,
  }
}

export const dropdownOptionsBindingApi: ComponentDatabindingApiFactory<
  AdaptedComponentWithOptions,
  DropdownOptionsConnectionConfig
> = (component, connectionConfig, context) => {
  const { logger } = appContext
  const { actions, getFieldType, getSchema, PresetVerboseMessage } = context

  const fetchDropdownOptions = async (
    fieldName: string,
    dataTransformer: DataTransformer,
  ) => {
    const isReference = getFieldType(fieldName)
      .map(fieldType => fieldType === 'reference')
      .getOrElse(false)

    if (isReference) {
      return getSchema()
        .chain(schema => {
          const refCollection = getFieldReferencedCollection(fieldName, schema)
          return getSchema(refCollection)
            .map(getSchemaDisplayField)
            .map(async displayField => {
              const { items } = await actions.fetchAll(fieldName)
              const options = items.map(record =>
                createOption(record, {
                  valueField: '_id',
                  labelField: displayField,
                  dataTransformer,
                }),
              )
              return orderBy(options, [option => option.label.toLowerCase()])
            })
        })
        .getOrElse(Promise.resolve([] as DropDownOption[]))
    } else {
      // This case happens if exp is off or as a fallback, when exp is on, but comp's value and options are connected to the same field
      // and we don't know whether it's a reference during completeControllerConfig step
      const { items } = await actions.fetchAll()

      const options = items.map(record =>
        createOption(record, {
          valueField: fieldName,
          dataTransformer,
        }),
      )

      return uniqBy(options, 'value')
    }
  }

  const handleSingleEmptyOption = (options: DropDownOption[]) => {
    const firstOption = options[0]
    if (
      options.length === 1 &&
      firstOption.label === '' &&
      firstOption.value === ''
    ) {
      return []
    }
    return options
  }

  const getDropdownOptions = async (
    fieldName: string,
    dataTransformer: DataTransformer,
  ) =>
    handleSingleEmptyOption(
      await fetchDropdownOptions(fieldName, dataTransformer),
    )

  const updateComponent = async () => {
    const { role } = component
    const { properties } = connectionConfig

    const options = await getDropdownOptions(
      properties.value.fieldName,
      (value: any) => transformFromRecordToView({ value, role }),
    )
    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
        component,
        description: { options },
      }),
    )
    component.setOptions(options)
  }

  const logVerboseForBinding = () => {
    const { properties } = connectionConfig
    const bindingDescription = { options: properties.value.fieldName }

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
        component,
        description: bindingDescription,
      }),
    )
  }

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),
    isValidConnection() {
      return isNonEmptyConfig(connectionConfig)
    },

    clear() {
      component.setOptions([])
    },

    bind() {
      logVerboseForBinding()
    },

    async onRecordsLoaded() {
      updateComponent()
    },

    async onCurrentRecordModified() {
      updateComponent()
    },
  }
}
