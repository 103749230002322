import { forEach } from 'lodash'
import type { ConnectionConfig } from '../../types'

type VerboseBindingDescription = Record<string, string>

export const getLogVerboseBindingDescription = (
  connectionConfig: ConnectionConfig,
): VerboseBindingDescription => {
  const { properties, events, behaviors } = connectionConfig
  const bindingDescription: VerboseBindingDescription = {}

  forEach(properties, ({ fieldName }, propName) => {
    bindingDescription[propName] = fieldName
  })
  forEach(events, ({ action }, eventName) => {
    bindingDescription[eventName] = action
  })
  forEach(behaviors, ({ type: behavior }) => {
    bindingDescription.text = behavior
  })

  return bindingDescription
}
