import { createFormEventPayload } from './helpers/automationUtils'

export const createListenersByEvent = ({ automationsClientCreator }) => {
  const automationsClient = automationsClientCreator()
  const reportFormEventToAutomation =
    automationsClient.reportFormEventToAutomationCreator()

  return {
    afterSave: async (
      { datasetId, getSchema, getState },
      prevRecord,
      nextRecord,
    ) => {
      const state = getState()
      const schema = getSchema()
      const isForm = Boolean(state.records.isForm)

      if (!isForm || !schema) {
        return
      }

      await reportFormEventToAutomation(
        createFormEventPayload({
          datasetId,
          record: nextRecord,
          schema,
          getSchema,
        }),
      )
    },
  }
}
