import { uniqBy } from 'lodash'
import type { ComponentDatabindingApiFactory } from './types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import type { RecordStoreRecord } from '../record-store/service'
import { appContext } from '../viewer-app-module/DataBindingAppContext'
import { VerboseMessage } from '../logger'
import { transformFromRecordToView } from '../components/transformData'
import { getFieldValue } from '../components/helpers'
import type { AdaptedComponentWithOptions } from '../inverted-dependencies/components'
import type { MobuiPickerOptionsConnectionConfig } from '../types'
import { isNonEmptyConfig } from '../components/helpers/connectionConfigUtils'

type DataTransformer = (value: any) => any

interface PickerOption {
  value: string
  label: string
}

interface OptionMapperConfig {
  valueField: string
  labelField?: string
  dataTransformer: DataTransformer
}

const createOption = (
  record: RecordStoreRecord,
  { valueField, labelField, dataTransformer }: OptionMapperConfig,
): PickerOption => {
  const value = dataTransformer(getFieldValue(record, valueField))
  return {
    value,
    label: labelField
      ? dataTransformer(getFieldValue(record, labelField))
      : value,
  }
}

export const mobuiPickerOptionsBindingApi: ComponentDatabindingApiFactory<
  AdaptedComponentWithOptions,
  MobuiPickerOptionsConnectionConfig
> = (component, connectionConfig, context) => {
  const { logger } = appContext
  const { actions, PresetVerboseMessage } = context

  const fetchMobuiPickerOptions = async (
    fieldName: string,
    dataTransformer: DataTransformer,
  ) => {
    const { items } = await actions.fetchAll()

    const options = items.map(record =>
      createOption(record, {
        valueField: fieldName,
        dataTransformer,
      }),
    )

    return uniqBy(options, 'value')
  }

  const handleSingleEmptyOption = (options: PickerOption[]) => {
    const firstOption = options[0]
    if (
      options.length === 1 &&
      firstOption.label === '' &&
      firstOption.value === ''
    ) {
      return []
    }

    return options
  }

  const getMobuiPickerOptions = async (
    fieldName: string,
    dataTransformer: DataTransformer,
  ) =>
    handleSingleEmptyOption(
      await fetchMobuiPickerOptions(fieldName, dataTransformer),
    )

  const updateComponent = async () => {
    const { role } = component
    const { properties } = connectionConfig

    const options = await getMobuiPickerOptions(
      properties.options.fieldName,
      (value: any) => transformFromRecordToView({ value, role }),
    )

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
        component,
        description: { options },
      }),
    )

    component.setOptions(options)
  }

  const logVerboseForBinding = () => {
    const { properties } = connectionConfig
    const bindingDescription = { options: properties.options.fieldName }

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
        component,
        description: bindingDescription,
      }),
    )
  }

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),
    isValidConnection() {
      return isNonEmptyConfig(connectionConfig)
    },

    clear() {
      component.setOptions([])
    },

    bind() {
      logVerboseForBinding()
    },

    async onRecordsLoaded() {
      updateComponent()
    },

    async onCurrentRecordModified() {
      updateComponent()
    },
  }
}
