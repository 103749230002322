import type { ComponentDatabindingApiFactory } from './types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import { appContext } from '../viewer-app-module/DataBindingAppContext'
import { VerboseMessage } from '../logger'
import { transformFromRecordToView } from '../components/transformData'
import { getFieldValue } from '../components/helpers'
import type { AdaptedComponentWithOptions } from '../inverted-dependencies/components'
import type { SelectionTagsOptionsConnectionConfig } from '../types'
import { isNonEmptyConfig } from '../components/helpers/connectionConfigUtils'

interface Option {
  value: string
  label: string
}

export const selectionTagsOptionsBindingApi: ComponentDatabindingApiFactory<
  AdaptedComponentWithOptions,
  SelectionTagsOptionsConnectionConfig
> = (component, connectionConfig, context) => {
  const { logger } = appContext
  const { actions, PresetVerboseMessage, modeIsLivePreview } = context

  const updateComponent = async () => {
    const { role } = component
    const {
      properties: {
        options: { fieldName },
      },
    } = connectionConfig

    const { items } = await actions.fetchAll()

    const options = items.reduce((acc: Option[], record) => {
      const value = transformFromRecordToView({
        value: getFieldValue(record, fieldName),
        role,
      })

      if (value) {
        acc.push({ value, label: value })
      }

      return acc
    }, [])

    if (modeIsLivePreview && options.length === 0) {
      return
    }

    component.setOptions(options)

    logger.log(
      new PresetVerboseMessage(VerboseMessage.types.COMPONENT.FILLED, {
        component,
        description: { options },
      }),
    )
  }

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),

    isValidConnection() {
      return isNonEmptyConfig(connectionConfig)
    },

    bind() {
      const {
        properties: {
          options: { fieldName },
        },
      } = connectionConfig
      logger.log(
        new PresetVerboseMessage(VerboseMessage.types.COMPONENT.BOUND, {
          component,
          description: { options: fieldName },
        }),
      )
    },

    clear() {
      component.setOptions([])
    },
    async onRecordsLoaded() {
      updateComponent()
    },

    async onCurrentRecordModified() {
      updateComponent()
    },
  }
}
