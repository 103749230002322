import type { ComponentDatabindingApiFactory } from './types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import type { UserFilterMinMaxInitialData } from '../dataset-controller/rootReducer'
import { getUserFilterInitialData as getUserFilterInitialDataFromState } from '../dataset-controller/rootReducer'
import type { AdaptedComponent } from '../inverted-dependencies/components'
import type { AdaptedRangeSlider } from '../inverted-dependencies/components/rangeSliderAdapter'
import type { UoUFilterConnectionConfig } from '../types'
import { handleUserInputFilterOnChange } from './utils'
import { isValidUserInputFilterConnectionConfig } from '../helpers/isValidUserInputFilterConfig'

export const userInputFilterRangeSliderBindingApi: ComponentDatabindingApiFactory<
  AdaptedComponent,
  UoUFilterConnectionConfig
> = (component, connectionConfig, context) => {
  const { getState } = context

  const getUserFilterInitialData = (fieldName: string) =>
    getUserFilterInitialDataFromState(getState())?.find(
      userFilterInitialData =>
        userFilterInitialData.fieldName === fieldName &&
        userFilterInitialData.role === component.role,
    )

  const setInitialComponentValue = ({
    firstRender,
  }: {
    firstRender: boolean
  }) => {
    const {
      userInputFilter: { fieldName, prop },
    } = connectionConfig

    const { min, max } = getUserFilterInitialData(
      fieldName,
    ) as UserFilterMinMaxInitialData

    if (firstRender) {
      ;(component as AdaptedRangeSlider).setBounds(min, max)
    }

    component.setValue([min, max], { propPath: prop })
  }

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),

    isValidConnection() {
      return isValidUserInputFilterConnectionConfig(connectionConfig)
    },

    bind() {
      handleUserInputFilterOnChange(component, connectionConfig, context)

      setInitialComponentValue({ firstRender: true })
    },

    resetUserFilter() {
      setInitialComponentValue({ firstRender: false })
    },
  }
}
